body {
  margin: 0;
}

.app {
  display: flex;
  justify-content: space-evenly;
}

.app__editors {
  min-height: 100vh;
  flex: 0.5;
  background-color: hsl(225, 6%, 25%);
  display: flex;
  flex-direction: column;
}

.app__output {
  flex: 0.5;
  display: flex;
}

.editor-container {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  max-height: 30vh;
  padding: .5rem;
  background-color: hsl(225, 6%, 25%);
}

.editor-container__logo {
  height: 17px;
  object-fit: contain;
  margin-right: 3px;
}

.editor-container.collapsed {
  flex-grow: 0;
}

.editor-container.collapsed .CodeMirror-scroll {
  position: absolute;
  overflow: hidden !important;
}

.expand-collapse-btn {
  margin-left: .5rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  outline: none;
}

.editor-title {
  font-family: Consolas;
  display: flex;
  justify-content: space-between;
  background-color: hsl(225, 6%, 13%);
  color: white;
  padding: .5rem .5rem .5rem 1rem;
  border-top-right-radius: .5rem;
  border-top-left-radius: .5rem;
}

.CodeMirror {
  height: 100% !important;
}

.code-mirror-wrapper {
  flex-grow: 1;
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
  overflow: hidden;
}

/* Scroll bar - not working as intended */

#editor::-webkit-scrollbar {
  width: 5px;
}

#editor::-webkit-scrollbar-track {
  background: #f1f1f1;
}

#editor::-webkit-scrollbar-thumb {
  background: red !important;
}

#editor::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media screen and (max-width: 1000px) {

  /*.app {
    flex-direction: column;
  }*/

  .app__editors {
    max-height: 35vh;
  }

  .app__output {
    /*flex: 1;*/
	flex: 0.5 1;
    height: 100vh;
    overflow-y: auto;
  }

  .editor-container {
    flex-grow: 0;
    min-height: 35vh;
  }

  .CodeMirror {
    min-height: 30vh;
  }

}